import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { google } from 'google-maps';

declare var google: google;

@Component({
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.css']
})
export class GoogleAutocompleteComponent implements OnInit, AfterViewInit {

	@Input() addressType: string;
	@Input() inputClass: string;
	@Output() setAddress: EventEmitter<any> = new EventEmitter();
	@Output() addressChange: EventEmitter<any> = new EventEmitter();
	@ViewChild('addresstext', { static: true }) addresstext: any;

	@Input() autocompleteInput: string;

	private autocomplete: google.maps.places.Autocomplete;

	constructor() {}

	ngOnInit() {
		this.autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
			componentRestrictions: { country: 'IT' },
			types: [this.addressType] // 'establishment' / 'address' / 'geocode'
		});
	}

	ngAfterViewInit() {
		google.maps.event.addListener(this.autocomplete, 'place_changed', () => this.setAddress.emit(this.autocomplete.getPlace()));
		google.maps.event.addDomListener(this.addresstext.nativeElement, 'keyup', () => this.addressChange.emit(this.autocompleteInput));
	}
}
